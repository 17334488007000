@import url(https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Peralta&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Podkova:wght@400;500;600;700;800&display=swap);
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  body {
    height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  color: black;
}

/*
 * CSS-spinner
 * https://projects.lukehaas.me/css-loaders/
 */
.spinner {
  color: #000000;
  font-size: 20px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0) scale(0.2);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.spinner--orange {
  color: #FF650E;
}

.spinner--big {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);

  margin: 100px auto;
  width: 1em;
  height: 1em;
}

.loading-container {
  width: 200px;
  height: 200px;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .loading-container {
    margin-top: 200px;
  }
}

.content {
  background-position: center;

  height: 807px;

  background-image: url(/static/media/background.a60b9564.png);
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pre-title {
  font-family: Reenie Beanie;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 44px;
  text-transform: uppercase;

  align-self: flex-start;

  padding: 100px 0 0 20px;

  text-align: center;
}

.title {
  font-family: Reenie Beanie;
  font-style: normal;
  font-weight: 500;
  font-size: 98px;
  line-height: 98px;
  text-transform: uppercase;

  font-size: 5em;

  text-align: center;
}

.meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  text-align: center;

  width: 300px;

  margin: 0 0 20px 0;
}

.meta-title {
  font-family: Peralta;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 44px;
  text-align: center;
}

.meta-text {
  font-family: Podkova;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.08em;
}

.description {
  font-family: Peralta;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  padding: 0 10px;
}

.description p {
  margin: 10px 0;
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  cursor: pointer;

  margin: 15px 0 15px 30px;
}

.checkbox--box {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;

  background-color: #FF650E;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.checkbox--text {
  margin: 10px 0 0 10px;

  font-family: Peralta;
  font-style: normal;
  font-weight: normal;
  font-size: 29px;
  line-height: 38px;
}

.checkbox--tick {
  margin: -20px 0 0 0;
}

@media only screen and (min-width: 768px) {
  .content {
    margin: auto;
    width: 807px;

    background-size: contain;
  }

  .description {
    width: 400px;
    padding: 0 0 0 60px;
  }

  .checkbox--text {
    margin: 10px 0 0 10px;
    width: 300px;
  }

  .meta-text {
    width: 130px;
  }

  .pre-title {
    padding: 100px 0 0 170px;
  }
}
