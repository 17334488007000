@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Peralta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Podkova:wght@400;500;600;700;800&display=swap');

.content {
  background-position: center;

  height: 807px;

  background-image: url("./img/background.png");
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pre-title {
  font-family: Reenie Beanie;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 44px;
  text-transform: uppercase;

  align-self: flex-start;

  padding: 100px 0 0 20px;

  text-align: center;
}

.title {
  font-family: Reenie Beanie;
  font-style: normal;
  font-weight: 500;
  font-size: 98px;
  line-height: 98px;
  text-transform: uppercase;

  font-size: 5em;

  text-align: center;
}

.meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  text-align: center;

  width: 300px;

  margin: 0 0 20px 0;
}

.meta-title {
  font-family: Peralta;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 44px;
  text-align: center;
}

.meta-text {
  font-family: Podkova;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.08em;
}

.description {
  font-family: Peralta;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  padding: 0 10px;
}

.description p {
  margin: 10px 0;
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  cursor: pointer;

  margin: 15px 0 15px 30px;
}

.checkbox--box {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;

  background-color: #FF650E;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.checkbox--text {
  margin: 10px 0 0 10px;

  font-family: Peralta;
  font-style: normal;
  font-weight: normal;
  font-size: 29px;
  line-height: 38px;
}

.checkbox--tick {
  margin: -20px 0 0 0;
}

@media only screen and (min-width: 768px) {
  .content {
    margin: auto;
    width: 807px;

    background-size: contain;
  }

  .description {
    width: 400px;
    padding: 0 0 0 60px;
  }

  .checkbox--text {
    margin: 10px 0 0 10px;
    width: 300px;
  }

  .meta-text {
    width: 130px;
  }

  .pre-title {
    padding: 100px 0 0 170px;
  }
}